.modal {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 1vw;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.4);
}


.modal-content {
    background-color: #fefefe;
    padding: 20px;
    width: 80%;
    max-width: 400px;
    text-align: center;
    border-radius: 10px;
    position: relative;
    padding-top: 40px; 
    padding-right: 40px; 
    padding-bottom: 40px; 
    padding-left: 40px; 
    font-size: 12px;
}


.close {
    color: #aaaaaa;
    font-size: 32px;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 16px;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
}